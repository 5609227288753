import { FieldValue, RunbookShowRunbook, StreamListStream, TaskBase, TaskLevel, TaskType } from './types'
import { Snippet } from 'main/services/queries/use-snippets'
import { apiClient } from '../api'
import {
  RunbookTaskBulkSkipResponse,
  RunbookTaskBulkUpdateResponse,
  RunbookTaskCreateResponse,
  RunbookTaskFinishResponse,
  RunbookTaskMultiplyResponse,
  RunbookTaskQuickUpdateResponse,
  RunbookTaskShowResponse,
  RunbookTaskStartResponse,
  RunbookTaskUpdateResponse,
  RunbookTaskValidateAbandonResponse
} from '../api/data-providers/runbook-types'

export const getTask = async ({
  runbookId,
  runbookVersionId,
  taskId
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
}) => {
  const { data } = await apiClient.get<RunbookTaskShowResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}`
  })

  return data
}

export type TaskEditTaskPayload = {
  task: Pick<
    TaskBase,
    | 'id'
    | 'name'
    | 'description'
    | 'duration'
    | 'stream_id'
    | 'task_type_id'
    | 'start_requirements'
    | 'end_requirements'
    | 'start_fixed'
    | 'end_fixed'
    | 'message'
    | 'level'
    | 'disable_notify'
    | 'field_values_attributes'
    | 'auto_finish'
  >
  predecessors: number[]
  recipients: {
    users: number[]
    runbook_teams: number[]
  }
  users: number[]
  runbook_teams: number[]
  linked_snippets?: Snippet[]
  linked_resource?: Omit<LinkedResource, 'meta'>
}

export type TaskEditRestrcitedTaskPayload = {
  task: Pick<TaskBase, 'message' | 'end_requirements' | 'duration' | 'disable_notify' | 'name' | 'description'>
  recipients: {
    users: number[]
    runbook_teams: number[]
  }
  users: number[]
  runbook_teams: number[]
  linked_snippets?: Snippet[]
}

export const setTask = async ({
  runbookId,
  runbookVersionId,
  taskId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
  payload: TaskEditTaskPayload | TaskEditRestrcitedTaskPayload
}) => {
  const { data } = await apiClient.patch<
    TaskEditTaskPayload | TaskEditRestrcitedTaskPayload,
    RunbookTaskUpdateResponse
  >({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}`,
    data: payload
  })

  return data
}

export type TaskStartPayload = {
  taskId?: number
  override: boolean
  field_values_attributes: FieldValue[]
  selected_successor_ids: number[]
}

export const startTask = async ({
  runbookId,
  runbookVersionId,
  taskId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
  payload: TaskStartPayload
}) => {
  const { data } = await apiClient.patch<TaskStartPayload, RunbookTaskStartResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/start`,
    data: payload
  })

  return data
}

export type TaskFinishPayload = {
  taskId?: number
  override: boolean
  field_values_attributes: FieldValue[]
  selected_successor_ids: number[]
}

export const finishTask = async ({
  runbookId,
  runbookVersionId,
  taskId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
  payload: TaskStartPayload
}) => {
  const { data } = await apiClient.patch<TaskFinishPayload, RunbookTaskFinishResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/finish`,
    data: payload
  })

  return data
}

export type TaskBulkSkipPayload = {
  comment: string
  task_ids: number[]
}

export const bulkSkipTasks = async ({
  runbookId,
  runbookVersionId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  payload: TaskBulkSkipPayload
}) => {
  const { data } = await apiClient.patch<TaskBulkSkipPayload, RunbookTaskBulkSkipResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/bulk_skip`,
    data: payload
  })

  return data
}

type TaskCreateTaskPayload = {
  auto_finish?: boolean | null
  disable_notify: boolean
  duration?: number
  end_fixed: number | null
  level: TaskLevel
  level_name?: string
  name: string
  stage: 'new'
  start_display?: number
  stream: StreamListStream
  stream_id: number
  task_type: TaskType
  task_type_id: number
}

type LinkedResource = {
  id: number
  type: 'Runbook'
  meta: {
    id: number
    meta: {}
    name: string
    runbook_type_id: number
    tasks_count: number
    template_status: string
  }
}

export type TaskCreatePayload = {
  task: TaskCreateTaskPayload
  base_task_id: null | number
  successors: number[]
  predecessors: number[]
  users: number[]
  runbook_teams: number[]
  integration_events: any[]
  runBook?: RunbookShowRunbook // yes the property name is correct..
  linked_resource?: LinkedResource
}

export const createTask = async ({
  runbookId,
  runbookVersionId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  payload: TaskCreatePayload
}) => {
  const { data } = await apiClient.post<TaskCreatePayload, RunbookTaskCreateResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks`,
    data: payload
  })

  return data as RunbookTaskCreateResponse
}

export type TaskTestPayload = {
  message: string | null
  type: 'comms_email' | 'comms_sms'
}

export const testTaskComms = async ({
  runbookId,
  runbookVersionId,
  taskId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
  payload: TaskTestPayload
}) => {
  return await apiClient.patch<TaskTestPayload, any>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/test`,
    data: payload
  })
}
export type QuickUpdateTaskPayload = {
  users?: number[]
  runbook_teams?: number[]
}

export const quickUpdateTask = async ({
  runbookId,
  runbookVersionId,
  taskId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
  payload: QuickUpdateTaskPayload
}) => {
  const { data } = await apiClient.patch<QuickUpdateTaskPayload, RunbookTaskQuickUpdateResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/quick_update`,
    data: payload
  })

  return data
}

export type TaskDuplicatePayload = {
  copy_type: 'sequence' | 'parallel'
  copies: string
  suffix?: string | null
}

export const duplicateTask = async ({
  runbookId,
  runbookVersionId,
  taskId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
  payload: TaskDuplicatePayload
}) => {
  const { data } = await apiClient.patch<TaskDuplicatePayload, RunbookTaskMultiplyResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/multiply`,
    data: payload
  })

  return data
}

export type ValidateAbandonTaskPayload = {
  selected_successor_ids: number[]
}

export const validateAbandonTask = async ({
  runbookId,
  runbookVersionId,
  taskId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
  payload: ValidateAbandonTaskPayload
}) => {
  const { data } = await apiClient.patch<ValidateAbandonTaskPayload, RunbookTaskValidateAbandonResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/validate_abandon`,
    data: payload
  })

  return data as RunbookTaskValidateAbandonResponse
}

export const fireIntegration = async ({
  runbookId,
  runbookVersionId,
  taskId
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
}) => {
  await apiClient.patch({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/fire_endpoint`
  })
}

export const cancelIntegration = async ({
  runbookId,
  runbookVersionId,
  taskId
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
}) => {
  await apiClient.patch({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/cancel_endpoint`
  })
}

export type TaskBulkUpdatePayload = {
  task_ids: number[]
  changes: {
    description?: string
    disable_notify?: boolean
    duration?: number
    end_fixed?: Date
    end_requirements?: 'any_can_end' | 'all_must_end' | 'same_must_end'
    fiekd_values_attributes?: FieldValue[]
    level?: TaskLevel
    predecessor_ids?: number[]
    predecessor_modify_type: 'add' | 'replace' | 'remove'
    start_fixed?: Date
    start_requirements?: 'any_can_start' | 'all_must_start'
    stream_id?: number
    task_type_id?: number
    teams?: number[]
    user_modify_type: 'add' | 'replace' | 'remove'
    users?: number[]
  }
}

export const bulkUpdateTasks = async ({
  runbookId,
  runbookVersionId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  payload: TaskBulkUpdatePayload
}) => {
  const { data } = await apiClient.patch<TaskBulkUpdatePayload, RunbookTaskBulkUpdateResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/bulk_update`,
    data: payload
  })

  return data
}
