import { useInfiniteQuery, useMutation, useQuery } from 'react-query'

import { RunbookComponent, RunbookTeam, RunbookVersion, StreamListStream, User } from './types'
import { apiClient_UNSTABLE } from 'main/services/api/api-client-unstable'
import {
  RunbookVersionCancelApprovalResponse,
  RunbookVersionCreateResponse,
  RunbookVersionMarkAsApprovedResponse,
  RunbookVersionRequestApprovalResponse,
  RunbookVersionSubmitReviewResponse
} from '../api/data-providers/runbook-types'
import { ApiError } from '../api'
import { QueryKeys } from './query-keys'

export type GetRunbookVersionResponse = {
  runbook_version: RunbookVersion
  meta: RunbookVersionMeta
}

export type RunbookVersionUser = Pick<
  User,
  | 'archived'
  | 'avatar'
  | 'color'
  | 'first_name'
  | 'handle'
  | 'has_role_on_runbook'
  | 'id'
  | 'is_admin'
  | 'last_name'
  | 'name'
  | 'not_in_account'
  | 'online'
  | 'status'
  | 'subject_type'
>

export type RunbookUser = Pick<
  User,
  | 'archived'
  | 'avatar'
  | 'color'
  | 'email'
  | 'first_name'
  | 'handle'
  | 'id'
  | 'last_name'
  | 'mobile_number_normalized'
  | 'name'
  | 'pref_sms'
  | 'pref_email'
  | 'unique_id'
> & {
  in_account: boolean
  registered: boolean
  runbook_admin: boolean
  runbook_teams: string[] | null
  streams: string[] | null
}

export type RunbookVersionPermissionsResponse = RunbookVersionMeta['permissions']['runbook_version']
export type StreamsPermissionsResponse = RunbookVersionMeta['permissions']['streams']
export type RunsPermissionsResponse = RunbookVersionMeta['permissions']['runs']
export type RunbookTeamsPermissionsResponse = RunbookVersionMeta['permissions']['runbook_teams']

export type RunbookVersionMeta = {
  permissions: {
    streams: {
      create: number[]
      create_tasks: number[]
    }
    runbook_teams: {
      create: number[]
    }
    runs: {
      create: number[]
      pause: number[]
      resume: number[]
      update: number[]
    }
    runbook_version: {
      import: number[]
      create: number[]
      adhoc_comms: number[]
      manage_template: number[]
      review: number[]
      destroy: number[]
    }
    teams: {
      update: number[]
    }
  }
  runbook_teams: RunbookTeam[]
  runbook_components: RunbookComponent[]
  users: RunbookVersionUser[]
  streams: StreamListStream[]
}

export type GetRunbookVersionsResponse = {
  runbook_versions: RunbookVersion[]
}

let controller: AbortController | undefined

export const getRunbookVersion = async (runbookId: string | number, runbookVersionId: string | number) => {
  if (controller) controller.abort()
  controller = new AbortController()
  const signal = controller.signal

  try {
    const { data } = await apiClient_UNSTABLE.get<GetRunbookVersionResponse>(
      `runbooks/${runbookId}/runbook_versions/${runbookVersionId}`,
      {
        signal
      }
    )
    return data
  } catch (error: any) {
    // TODO: handle any errors
    throw error
  }
}

export const getRunbookVersions = async (runbookId: string | number) => {
  try {
    const { data } = await apiClient_UNSTABLE.get<GetRunbookVersionsResponse>(`runbooks/${runbookId}/runbook_versions`)
    return data
  } catch (error: any) {
    // TODO: handle any errors
    throw error
  }
}

export const useGetRunbookVersions = (runbookId: number) => {
  return useQuery<RunbookVersion[], ApiError>(['runbook-versions', runbookId], async () => {
    const data = await getRunbookVersions(runbookId)
    return data.runbook_versions
  })
}

export const createNewRunbookVersion = async (runbookId: string | number, payload: { base_version_id: number }) => {
  const { data } = await apiClient_UNSTABLE.post<RunbookVersionCreateResponse>(
    `runbooks/${runbookId}/runbook_versions`,
    payload
  )
  return data
}

export const useCreateNewRunbookVersion = (runbookId: number) => {
  return useMutation<
    RunbookVersionCreateResponse,
    ApiError,
    { base_version_id: number; reapproval?: boolean; reviewer_ids?: number[] }
  >('createRunbookVersion', async data => {
    return createNewRunbookVersion(runbookId, data)
  })
}

export const convertRunbookVersionToTemplate = async (
  runbookId: string | number,
  runbookVersionId: string | number
) => {
  const { data } = await apiClient_UNSTABLE.post(
    `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/convert_to_template`
  )
  return data
}

export type RequestApprovalPayload = { reviewer_ids: number[] }

export const useRequestApproval = (runbookId: number, runbookVersionId: number) => {
  return useMutation<RunbookVersionRequestApprovalResponse, ApiError, RequestApprovalPayload>(
    'requestApproval',
    async (payload: RequestApprovalPayload) => {
      const { data } = await apiClient_UNSTABLE.post(
        `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/request_approval`,
        payload
      )
      return data
    }
  )
}

export const useCancelApproval = (runbookId: number, runbookVersionId: number) => {
  return useMutation<RunbookVersionCancelApprovalResponse, ApiError>('cancelApproval', async () => {
    const { data } = await apiClient_UNSTABLE.post(
      `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/cancel_approval`
    )
    return data
  })
}

export const useMarkAsApproved = (runbookId: number, runbookVersionId: number) => {
  return useMutation<RunbookVersionMarkAsApprovedResponse, ApiError>('markAsApproved', async () => {
    const { data } = await apiClient_UNSTABLE.post(
      `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/mark_as_approved`
    )
    return data
  })
}

export type ReviewPayload = {
  decision: 'approved' | 'rejected'
  comment?: string
}

export const useSubmitReview = (runbookId: number, runbookVersionId: number) => {
  return useMutation<RunbookVersionSubmitReviewResponse, ApiError, ReviewPayload>(
    'submitReview',
    async (payload: ReviewPayload) => {
      const { data } = await apiClient_UNSTABLE.post(
        `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/submit_review`,
        payload
      )
      return data
    }
  )
}

type ExportTasksProps = {
  runbookId: string | number
  runbookVersionId: string | number
  format: 'csv' | 'xlsx'
}

export type ExportTasksResponse = {
  data: ArrayBuffer
  headers: {
    'content-type': string
    'content-disposition': string
    [key: string]: any
  }
}

export type ExportTasksPayload = {
  exclude_missing_tasks?: boolean
  task_ids: number[]
  timezone: string
}

export const exportTasks = async ({
  runbookId,
  runbookVersionId,
  format,
  ...payload
}: ExportTasksProps & ExportTasksPayload) => {
  const response = await apiClient_UNSTABLE.post<ExportTasksResponse>(
    `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/dashboard_${format}`,
    payload,
    {
      responseType: 'arraybuffer'
    }
  )
  return response
}

const DEFAULT_LIMIT = 40

const DEFAULTS = {
  order: 'first_name',
  sort_direction: 'asc',
  per_page: DEFAULT_LIMIT
}

export type RunbookVersionAllUsersResponse = {
  meta: {
    total_count: number
    total_filtered_count: number
  }
  runbook_users: RunbookUser[]
}

export function useRunbookVersionAllUsers({
  runbookId,
  runbookVersionId,
  params = {}
}: {
  runbookId: number
  runbookVersionId: number
  params?: Record<string, any>
}) {
  const { q, ...restParams } = params
  const serverParams = { ...DEFAULTS, ...restParams, query: q }

  const getRunbookVersionAllUsers = async (offset: number = 0) => {
    const { data } = await apiClient_UNSTABLE.get<RunbookVersionAllUsersResponse>(
      `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/all_users`,
      { params: { ...serverParams, offset } }
    )

    return data
  }

  return useInfiniteQuery<RunbookVersionAllUsersResponse, ApiError>(
    [QueryKeys.RunbookVersionUsers, String(runbookId), serverParams],
    ({ pageParam = 0 }) => getRunbookVersionAllUsers(pageParam),
    {
      getNextPageParam: (_, allGroups) => {
        const totalResultCount = allGroups[0]?.meta?.total_filtered_count
        const lastOffset = allGroups.length * serverParams.per_page
        return totalResultCount > serverParams.per_page && totalResultCount > lastOffset ? lastOffset : undefined
      }
    }
  )
}
